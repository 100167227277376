<template>
  <div id="categories-list">
    <v-snackbar v-model="isSnackbarVisible" :color="snackbar.color" :timeout="snackbar.timeout" top right auto-height>
      <v-layout align-center pr-4>
        <v-icon class="pr-3" dark large>{{ snackbar.icon == 'success' ? icons.mdiCheckBold : icons.mdiCheckBold }}
        </v-icon>

        <v-layout column>
          <div>
            <strong>{{ snackbar.title }}</strong>
          </div>
          <div>{{ snackbar.text }}</div>
        </v-layout>
      </v-layout>
    </v-snackbar>

    <!-- titulo -->
    <h2 class="mx-2 m-2">NUEVA ORDEN v2</h2>

    <!-- modal 2 MULTIPUNTOS -->

    <v-card>
      <v-form @submit.prevent="saveData2">

        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="6">
                <label for="">Fecha orden</label><br />
                <label class="dark" for="">{{ row.fecha_orden }}</label><br />
                <!-- 
                <v-text-field
                  label=""
                  dense
                  v-model="row.fecha_orden"
                  :rules="[() => !!row.fecha_orden || 'Este campo es requerido.']"
                  :error-messages="errorMessages"
                  ref="fecha"
                  required
                  type="datetime-local"
                ></v-text-field> -->
              </v-col>

              <v-col cols="6">
                <!-- <label for="">Fecha entregada</label><br />
                  <input
                    class="form-control"
                    readonly
                    v-model="row.fecha_finalizado"
                    type="datetime-local"
                    name="datetime"
                  /> -->
              </v-col>

              <v-col cols="6">
                <v-select class="test" quired v-model="row.cliente_id" :items="customersList" label="Cliente" dense
                  item-text="nombre" item-value="id"></v-select>
              </v-col>
              <v-col cols="6" sm="6">
                <v-select v-model="row.tipo" :items="tipoPedido" label="Tipo de pedido" dense item-text="nombre"
                  item-value="id"></v-select>
              </v-col>

              <!-- <v-col cols="12" sm="12" md="12">
                <v-text-field 
                  label="Descripción"
                  dense
                  v-model="row.descripcion"
                  :rules="[() => !!row.descripcion || 'Este campo es requerido.']"
                  :error-messages="errorMessages"
                  ref="descripcion"
                  required
                ></v-text-field>
              </v-col> -->

              <!-- mapa -->
              <v-col cols="12" sm="12" md="12">
                <div id="map" style="height: 0px"></div>
              </v-col>

              <!-- <v-col cols="12" sm="12" md="12">
                <v-text-field
                  label="Coordenadas"
                  dense
                  v-model="row.coordenadas_inicio"
                  ref="cci"
                  type="text"
                ></v-text-field>
              </v-col> -->

              <v-col cols="6" sm="6" md="6">
                <!-- <input class="form-control" type="text" v-model="address" placeholder="Dirección a buscar" id="" /> -->
                <!-- <input class="form-control" v-model="address" v-gmaps-searchbox="vm" /> -->
                <input class="form-control" type="text" placeholder="Dirección a buscar" ref="origin" />

                {{ address }}
                <!-- <v-text-field
                  v-model="address"
                  label="Buscar dirección"
                  placeholder="dirección"
                  class="mb-6"
                ></v-text-field> -->
              </v-col>

              <!-- <v-col cols="6" sm="6" md="6">
                <v-btn color="secondary" @click="buscarDirecciones()">Obtener datos de la dirección</v-btn>
              </v-col> -->

              <v-col v-if="showMapa != 3" cols="12" sm="12" md="12">
                <div style="height: 300px">
                  <google-map @click="addPoint($event)" id="map1" :center="centerM" :zoom="14"
                    style="width: 100%; height: 300px" :panTo="panTo">
                    <GmapMarker :key="index" v-for="(m, index) in coordinates" :position="m.position"
                      @click="center = m.position" />
                  </google-map>

                  <!-- <GMapMap :center="center" :zoom="7" map-type-id="terrain" style="width: 100vw; height: 900px">
                  </GMapMap> -->
                  <!-- <MglMap
                    :center.sync="center"
                    :accessToken="accessToken"
                    :mapStyle.sync="mapStyle"
                    :zoom="zoom"
                    @click="clickMapaMultipuntos"
                  >
                    <MglMarker v-for="marker in coordinates" :coordinates="marker.coordinates" :key="marker.id">
                      <MglPopup>
                        <div>
                          <span style="font-size: 25px important!">{{
                            $moment(marker.comentario).utc().format('DD-MM-YYYY hh:mm:ss')
                          }}</span>
                        </div>
                      </MglPopup>
                    </MglMarker>
                  </MglMap> -->
                </div>
              </v-col>

              <!-- <v-col cols="12"> -->
              <!-- <input type="text"> -->
              <!-- <v-select
                  @change="seleccionarDireccion($event)"
                  required
                  v-model="cliente"
                  :items="addressList"
                  label="Direcciones encontradas:"
                  dense
                  item-text="name"
                  item-value="center"
                ></v-select> -->
              <!-- </v-col> -->

              <!-- <v-col cols="6" sm="6" md="6">
              <label>{{row.tipo}}</label>
              </v-col> -->
              <!-- :rules="[() => !!rowAddress.descripcion || 'Este campo es requerido.']"
              :error-messages="errorMessages" -->
              <v-col cols="12" sm="6" md="6">
                <v-text-field label="Dirección exacta" dense v-model="rowAddress.direccionExacta" ref="descripcion"
                  required></v-text-field>
              </v-col>

              <v-col cols="12" sm="6" md="6">
                <v-text-field label="Teléfono" dense v-model="rowAddress.telefono"
                  :rules="[() => !!rowAddress.telefono || 'Este campo es requerido.']" :error-messages="errorMessages"
                  ref="descripcion" required></v-text-field>
              </v-col>

              <v-col cols="6" sm="6" md="6">
                <v-text-field label="Detalle" dense v-model="rowAddress.detalle"
                  :rules="[() => !!rowAddress.detalle || 'Este campo es requerido.']" :error-messages="errorMessages"
                  ref="descripcion" required></v-text-field>
              </v-col>
              <v-col cols="6" sm="6" md="6">
                <v-text-field label="¿Qué hacemos en el punto?" dense v-model="rowAddress.descripcion"
                  :rules="[() => !!rowAddress.descripcion || 'Este campo es requerido.']"
                  :error-messages="errorMessages" ref="descripcion" required></v-text-field>
              </v-col>
              <v-col cols="6" sm="6" md="6">
                <v-text-field label="Total a recaudar" dense v-model="rowAddress.total_arecaudar"
                  :error-messages="errorMessages" ref="total_arecaudar"></v-text-field>
              </v-col>

              <v-col cols="12">
                <v-btn color="secondary" @click="agregarPunto()"> Agregar punto </v-btn>
              </v-col>

              <!-- inicio direcciones -->
              <v-col cols="12" sm="12" md="12">
                <v-row>
                  <v-flex xs12 sm12 md12 lg12 v-for="index of coordenadasMultipunto" :key="index.id">
                    <v-card>
                      <v-card-text>{{ index.nombre }}

                        <v-btn style="padding: 1px !important; min-width: 1px" color="error"
                          @click="removePoint(index)">
                          <v-icon center>{{ icons.mdiDeleteOutline }} </v-icon>
                        </v-btn>
                      </v-card-text>
                    </v-card>
                  </v-flex>
                </v-row>
                <!-- fin direcciones -->
              </v-col>

              <v-row>
                <v-col cols="6" sm="6" md="6">
                  <v-text-field label="Depósito" dense v-model="row.deposito" :error-messages="errorMessages"
                    ref="Deposito"></v-text-field>
                </v-col>
                <v-col cols="6" sm="6" md="6">
                  <v-text-field label="Banco" dense v-model="row.banco" :error-messages="errorMessages"
                    ref="Banco"></v-text-field>
                </v-col>
                <v-col cols="6" sm="6" md="6">
                  <v-text-field label="Número de cuenta" dense v-model="row.numero_cuenta"
                    :error-messages="errorMessages" ref="numero_cuenta"></v-text-field>
                </v-col>
              </v-row>

              <v-col cols="6" sm="6" md="6">
                <v-btn color="secondary" @click="calcularPrecioMultipuntos()"> Calcular total </v-btn>
              </v-col>

              <v-col cols="6" sm="6" md="6">
                <v-text-field label="Total" dense v-model="row.total" ref="total" :key="row.total"
                  type="number"></v-text-field>
              </v-col>

              <v-col cols="12" v-if="distanciaViaje > 0">
                {{ calculos }}
              </v-col>

              <v-col cols="6">
                <v-text-field label="Estado" dense type="text" min="1" readonly :value="row.estado == 0
                  ? 'En espera'
                  : row.estado > 0 && row.estado < 99
                    ? 'En proceso'
                    : row.estado == 99
                      ? 'Finalizada'
                      : '--'
                  "></v-text-field>
              </v-col>

              <v-col cols="6">
                <v-select v-model="row.metodo_pago" :items="metodoPago" label="Método de pago" dense item-text="nombre"
                  item-value="nombre"></v-select>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" outlined @click="isDialogVisible2 = false"> Cerrar </v-btn>
          <v-btn type="submit" color="success">
            <!-- @click="isDialogVisible = false" -->
            Guardar
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </div>
</template>

<script>
import { mdiCheckBold, mdiDeleteOutline, mdiMapSearch, mdiPencil, mdiPlusCircle } from '@mdi/js';
import { ref } from '@vue/composition-api';

import { MglGeojsonLayer, MglGeolocateControl, MglMap, MglMarker, MglNavigationControl, MglPopup } from 'vue-mapbox';

// import VueGmaps from 'vue-gmaps'

export default {
  components: {
    MglMap,
    MglMarker,
    MglGeojsonLayer,
    MglNavigationControl,
    MglGeolocateControl,
    MglPopup,
  },
  data() {
    return {
      icons: { mdiDeleteOutline, mdiPencil, mdiPlusCircle, mdiCheckBold, mdiMapSearch },
      items: [],
      table: 'ordenes',
      tableColumns: [
        { text: 'id', value: 'id', sortable: true },
        { text: 'Fecha Orden', value: 'fecha_orden', sortable: true },
        { text: 'Fecha Finalizado', value: 'fecha_finalizado', sortable: true },
        { text: 'Total', value: 'total', sortable: true },
        { text: 'Estado', value: 'estado', sortable: true },
        { text: 'Acciones', value: 'acciones', sortable: false },

        // {id:0, name:}
      ],
      loading: false,
      totalItems: 0,
      isDialogVisible: false,
      row: {
        coordenadas_inicio: '',
        coordenadas_entrega: '',
        total: 0,
        tipo: 0,
      },
      modalTitle: 'Nueva Orden',
      customersList: [],
      categoriesList: [],
      subcategoriesList: [],
      commerceList: [],
      formHasErrors: false,
      errorMessages: '',
      form: ref(null),
      valid: ref(true),
      isSnackbarVisible: false,
      message: '',
      messageColor: 'primary',
      icon: '',
      snackbar: {
        color: 'success',
        icon: 'check_circle',
        mode: 'multi-line',
        title: 'Success',
        text: 'That worked, hoorah.',
        visible: true,
      },
      statusList: [{ nombre: 'En espera' }, { nombre: 'Inactivo' }],
      tipoPedido: [
        { id: 1, nombre: 'Enviar a traer desde mi ubicación' },
        { id: 2, nombre: 'Multi Puntos' },
      ],
      calculos: '',
      distanciaViaje: 0,
      metodoPago: [{ nombre: 'Tarjeta de C.' }, { nombre: 'Efectivo' }],

      accessToken: 'pk.eyJ1IjoicGVkaWRvc3lhIiwiYSI6ImNsMnNkNGl4ODBqaDgzZW8zN3VvY2c1YWMifQ.sOFdwHG62peplh1SCnpxgQ',
      googleToken: 'AIzaSyAcjiwmtcAvLbb7J8X5rxDoWWmVEYJTUGA', //'pk.eyJ1IjoicGVkaWRvc3lhIiwiYSI6ImNsMnNkNGl4ODBqaDgzZW8zN3VvY2c1YWMifQ.sOFdwHG62peplh1SCnpxgQ',
      mapStyle: 'mapbox://styles/mapbox/streets-v11',
      stateList: [{ name: 'Activo' }, { name: 'Pendiente' }],

      geojson: {
        type: 'FeatureCollection',
        features: [
          {
            properties: { name: 'south park' },
            geometry: {
              coordinates: [-95.2358865737915, 38.96194491354418],
            },
          },
          {
            properties: { name: 'Buford M Watson, Jr. Park' },
            geometry: {
              coordinates: [-95.23893356323242, 38.97148792405487],
            },
          },
        ],
      },
      layerId: 'firstLayer',
      sourceId: 'firstSource',
      coordinates: [],
      center: [-86.108508886867, 11.912447380368315],
      centerM: { lat: 11.912447380368315, lng: -86.108508886867 },
      zoom: 8,
      showMapa: 0,
      coordenadas: '',
      tipocoord: 0,
      isDialogVisible2: false,
      coordenadasMultipunto: [],
      calculosMultipuntos: [],
      address: '',
      addressList: [],
      elMapa: null,
      rowAddress: {},
      cliente: '',
      place_id: '',
      panTo: {},
    }
  },
  async mounted() {
    this.row.fecha_orden = this.$moment().format('DD-MM-YYYY HH:MM:ss')
    var options = {
      componentRestrictions: { country: 'Ni' },
    }
    const autocomplete = new google.maps.places.Autocomplete(this.$refs['origin'], options)

    autocomplete.addListener('place_changed', async () => {
      const place = autocomplete.getPlace()
      this.place_id = place.place_id
      await this.buscarDirecciones()

      // console.log('datas en mounted', this.datas)
    })

    await this.getData()
    await this.getCategories()
    await this.getCommerce()
    await this.getCustomers()
  },
  methods: {
    addPoint(e) {
      let data = JSON.parse(JSON.stringify(e))
      //data =
      console.log('ee', data)
      //let dd = {lat:data.latLng.lat, lng:data.latLng.lng}
      //this.centerM = { lat: data.latLng.lat, lng: data.latLng.lng }
      this.panTo = { lat: data.latLng.lat, lng: data.latLng.lng }
      this.row.coordenadas_inicio = data.latLng.lat + ',' + data.latLng.lng
      this.coordinates = []
      this.coordinates.push({ position: this.panTo })
    },
    getAddressData: function (addressData, placeResultData, id) {
      this.address = addressData
    },
    async centerAndAnimate(lat, lng) {
      try {
        console.log('actualizando, centrando y animando camara')
        this.center = lat + ', ' + lng
        //this.elMapa.map.setCenter({
        // this.elMapa.map.setCenter({
        //   lat: lat, //52.3602160, // mandatory
        //   lng: lng, //4.8891680, // mandatory
        //   animated: true, // default true,
        //   zoomLevel: 20,
        // })
        // this.elMapa.map.animateCamera({
        //   // this is where we animate to
        //   target: {
        //     lat: lat,
        //     lng: lng,
        //   },
        //   zoomLevel: 15.5, // Android
        //   //altitude: 2000, // iOS (meters from the ground)
        //   //bearing: 270, // Where the camera is pointing, 0-360 (degrees)
        //   tilt: 50,
        //   duration: 2000, // default 10000 (milliseconds)
        // })
        return true
      } catch (error) {
        console.log('error', error)
        return false
      }
    },
    async onMapReady(args) {
      console.log('mapa leido')
      this.elMapa = args
      //await centerAndAnimate(args, this.lat, this.lng)
    },
    async seleccionarDireccion(item) {
      let lista = [...this.addressList]
      let pos = lista.find(x => x.center == item)
      this.rowAddress.direccionExacta = pos.name
      console.log('posición', pos)
      console.log(item)
      this.row.coordenadas_inicio = item[1] + ',' + item[0]
      this.coordinates = []
      this.coordenadas = item[0] + ',' + item[1]
      let data = { coordinates: JSON.parse('[' + this.coordenadas + ']'), cliente_id: 1 }
      this.coordinates.push(data)
      // await this.centerAndAnimate(item[0], item[1])
    },

    async buscarDirecciones() {
      console.log(this.place_id)
      var request = {
        placeId: this.place_id,
        fields: ['name', 'geometry', 'address_components'],
      }
      let the = this
      let service = new google.maps.places.PlacesService(map)
      let datass = service.getDetails(request, callback)
      // console.log('ss', service)

      function callback(place, status) {
        // if (status == google.maps.places.PlacesServiceStatus.OK) {
        //   //createMarker(place)
        console.log(JSON.stringify(place))
        let ccTmp = JSON.stringify(place.geometry.location)
        ccTmp = JSON.parse(ccTmp)
        the.address = [ccTmp.lat, ccTmp.lng]
        the.centerM = { lat: ccTmp.lat, lng: ccTmp.lng }
        the.row.coordenadas_inicio = ccTmp.lat + ',' + ccTmp.lng
        the.coordinates.push({ position: the.centerM })
        the.rowAddress.direccionExacta =
          place.name +
          ', ' +
          place.address_components[1].long_name +
          ', ' +
          place.address_components[0].long_name +
          ', ' +
          place.address_components[3].long_name
        // }
        //the.showMapa = 1
      }

      // this.addressList = []
      // this.rowAddress.direccionExacta =
      //   this.vm.place.address_components[1].long_name + ',' + this.vm.place.formatted_address
      // this.row.coordenadas_inicio = this.vm.place.geometry.location.lat + ',' + this.vm.place.geometry.location.lng
      // console.log(this.vm)

      // const options = {
      //   method: "POST",
      //   headers: { "Content-Type": "application/json" },
      //   body: JSON.stringify(data)
      // };
      // 'https://api.mapbox.com/geocoding/v5/mapbox.places/' +
      // '.json?proximity=ip&types=place%2Cpostcode%2Caddress&country=ni&types=place,poi,address,neighborhood,district,locality&language=es&access_token=' +
      // let address = this.address.replace(' ', '%')
      // let url =
      //   'https://maps.googleapis.com/maps/api/place/textsearch/json?query=' +
      //   address +
      //   '%Managua&key=' +
      //   this.googleToken

      // // var axios = require('axios')

      // var config = {
      //   method: 'get',
      //   url: url,
      //   headers: {},
      // }

      // //      axios.get('/auth/me').then(response => {

      // axios(config)
      //   .then(function (response) {
      //     console.log(JSON.stringify(response.data))
      //   })
      //   .catch(function (error) {
      //     console.log(error)
      //   })

      // return
      //let req = await fetch(url, options)
      // let res = await req.json()
      // console.log('direcciones--->', res)
      // this.coordinates = []
      // for await (let it of res.results) {
      //   // let coord=it.split(',')

      //   this.coordenadas = it.geometry.location.lat + ', ' + it.geometry.location.lng
      //   // this.row.coordenadas_inicio = event.mapboxEvent.lngLat.lat + ',' + event.mapboxEvent.lngLat.lng

      //   let data = { coordinates: JSON.parse('[' + this.coordenadas + ']'), cliente_id: 1 }
      //   this.coordinates.push(data)
      //   let dataAddress = {
      //     id: it.id,
      //     center: this.coordenadas,
      //     name: it.name + ' ' + it.formatted_address,
      //   }
      //   this.addressList.push(dataAddress)
      // }
      // console.log(this.coordinates)

      // res.features

      // https://api.mapbox.com/geocoding/v5/mapbox.places/buenos%20aires%20rivas.json?proximity=ip&types=place%2Cpostcode%2Caddress&access_token=YOUR_MAPBOX_ACCESS_TOKEN
    },
    async calcularPrecioMultipuntos() {
      console.log('************ iniciando calculos ***************')
      console.log(this.calculosMultipuntos)
      //obtener distancia, tiempo etc.
      this.calculosMultipuntos = []
      this.loading = true
      let contador1 = 0
      let contador2 = 1
      let limite = this.coordenadasMultipunto.length - 1

      //agregar el primer registro, sin precioxdistancia, distanciaviaje,etc

      this.calculosMultipuntos.push({
        id: 0,
        tiempo_viaje: 0,
        distancia_viaje: 0,
        precio_x_distancia: 0,
        total: 0,
        calculos: `--`,
        coordenadas_inicio: '',
        coordenadas_entrega: this.coordenadasMultipunto[contador1].coordenadas,
        direccion: this.coordenadasMultipunto[contador1].nombre,
        telefono: this.coordenadasMultipunto[contador1].telefono,
        descripcion: this.coordenadasMultipunto[contador1].descripcion,
      })
      let resTarifas = await this.$store.dispatch('get', { path: 'tarifas/getById/1' })
      let contadorit = 1
      for (let ii = 0; ii < limite; ii++) {
        console.log(
          ii + '-',
          this.coordenadasMultipunto[contador1].coordenadas,
          ',',
          this.coordenadasMultipunto[contador2].coordenadas,
        )
        let dataCoord = await this.getDataCoords(
          this.coordenadasMultipunto[contador1].coordenadas,
          this.coordenadasMultipunto[contador2].coordenadas,
        )
        let resp = await dataCoord.json()
        let tiempoViaje = (resp.routes[0].duration.toFixed(0) / 60).toFixed(2)
        let distanciaViaje = resp.routes[0].distance //< 1000 ? resp.routes[0].distance.toFixed(2)  : (resp.routes[0].distance / 1000).toFixed(2)
        console.log('dataco', tiempoViaje)
        console.log('dataco', distanciaViaje)

        console.log('resTarifas', resTarifas)
        let precio_x_distancia =
          distanciaViaje < 5 ? resTarifas.precio_x_metro_30_max : resTarifas.precio_x_metro_60_max
        let total =
          Number(distanciaViaje) * precio_x_distancia +
          resTarifas.precio_x_retirar +
          resTarifas.publicidad +
          resTarifas.precio_x_deposito +
          resTarifas.precio_x_entregar
        this.calculosMultipuntos.push({
          id: contadorit,
          tiempo_viaje: tiempoViaje,
          distancia_viaje: distanciaViaje,
          precio_x_distancia: precio_x_distancia,
          total: total,
          calculos: `Distancia aprox.: ${distanciaViaje} m., Tiempo aprox.: ${tiempoViaje} min.`,
          coordenadas_inicio: this.coordenadasMultipunto[contador1].coordenadas,
          coordenadas_entrega: this.coordenadasMultipunto[contador2].coordenadas,
          direccion: this.coordenadasMultipunto[contador2].nombre,
          telefono: this.coordenadasMultipunto[contador2].telefono,
          descripcion: this.coordenadasMultipunto[contador2].descripcion,
        })

        contador1++
        contador2++
        contadorit++
      }
      //pintando puntos en mapa
      this.coordinates = []
      for await (let itm of this.coordenadasMultipunto) {
        let cc1 = itm.coordenadas
        cc1 = cc1.split(',')

        this.coordenadas = cc1[1] + ', ' + cc1[0]
        //this.row.coordenadas_inicio = event.mapboxEvent.lngLat.lat + ',' + event.mapboxEvent.lngLat.lng
        let data = { coordinates: JSON.parse('[' + this.coordenadas + ']'), cliente_id: 1 }
        this.coordinates.push(data)
      }
      /////////
      console.log('for out -->', this.calculosMultipuntos)
      let total = 0

      //////////////////////////////
      if (this.coordenadasMultipunto.length > 2) {
        this.row.total = (this.coordenadasMultipunto.length - 1) * Number(resTarifas.precio_x_cada_punto_multipuntos);
        //console.log("calculando puntos....", this.total);
      } else {
        this.row.total = this.calculosMultipuntos
          .reduce((a, b) => {
            return a + b.total
          }, 0)
          .toFixed(2)

      }
      ///////////////////////


      this.loading = false
    },
    removePoint(item) {
      console.log('eliminando', item)
      let itt = [...this.coordenadasMultipunto]
      console.log('cc', itt)
      let result = itt.indexOf(item)
      console.log('eliminando', result)
      if (result > -1) {
        this.coordenadasMultipunto.splice(result, 1)
      }
    },
    clickMapaMultipuntos(event) {
      this.coordinates = []
      this.coordenadas = event.mapboxEvent.lngLat.lng + ', ' + event.mapboxEvent.lngLat.lat
      this.row.coordenadas_inicio = event.mapboxEvent.lngLat.lat + ',' + event.mapboxEvent.lngLat.lng

      let data = { coordinates: JSON.parse('[' + this.coordenadas + ']'), cliente_id: 1 }
      this.coordinates.push(data)
    },
    mostrarToast(titulo, mensaje, tipo) {
      this.snackbar = {
        color: tipo,
        icon: tipo,
        mode: 'multi-line',
        title: titulo,
        text: mensaje, //'Debes seleccionar coordenada / dirección!',
        timeout: '2500',
      }
      this.isSnackbarVisible = true
    },
    async agregarPunto() {
      try {
        if (this.row.tipo == 0 || this.row.tipo == null) {
          this.mostrarToast('Error!', 'Debes seleccionar tipo de orden!.', 'error')
          return
        }
        if (this.coordenadasMultipunto.length == 2 && this.row.tipo == 1) {
          this.mostrarToast('Error!', 'Si quieres agregar más puntos selecciona multipuntos!', 'error')
          return
        }
        if (this.row.cliente_id == 0 || this.row.cliente_id == null) {
          this.mostrarToast('Error!', 'Debes seleccionar Cliente', 'error')
          return
        }
        if (this.row.coordenadas_inicio == '' || this.row.coordenadas_inicio == null) {
          this.mostrarToast('Error!', 'Debes seleccionar coordenada / dirección!', 'error')
          return
        }
        if (this.rowAddress.detalle == '' || this.rowAddress.detalle == null) {
          this.mostrarToast('Error!', 'Falta detalle!', 'error')
          return
        }
        if (this.rowAddress.direccionExacta == '' || this.rowAddress.direccionExacta == null) {
          this.mostrarToast('Error!', 'Falta dirección!', 'error')
          return
        }
        if (this.rowAddress.telefono == '' || this.rowAddress.telefono == null) {
          this.mostrarToast('Error!', 'Falta teléfono!', 'error')
          return
        }

        let arrTmp = [...this.coordenadasMultipunto]
        console.log('ejecutar', arrTmp)
        let contador = await (this.coordenadasMultipunto == null || this.coordenadasMultipunto == []
          ? 1
          : this.coordenadasMultipunto.length++)
        // let itt1 = {
        //   id: contador,
        //   coordenadas: this.row.coordenadas_inicio
        // }

        arrTmp.push({
          id: contador,
          coordenadas: this.row.coordenadas_inicio,
          descripcion: this.rowAddress.detalle,
          telefono: this.rowAddress.telefono,
          nombre: this.rowAddress.direccionExacta,
        })
        console.log('ejecutar2', arrTmp)
        let nu = arrTmp.indexOf(x => x == null)
        console.log('nu', nu)
        //this.coordenadasMultipunto.splice(nu,1)
        let cconta = 0
        for await (let iit of arrTmp) {
          console.log('ii ', iit)
          if (iit == undefined) {
            arrTmp.splice(cconta, 1)
          }
          cconta++
        }
        this.coordenadasMultipunto = arrTmp
      } catch (error) {
        console.log('error', error)
      }
    },
    async saveData2() {
      try {
        if (this.coordenadasMultipunto.length < 2) {
          this.mostrarToast('Error!', 'Debes agregar más puntos!', 'error')
          return
        }
        if (this.row.metodo_pago == 0 || this.row.metodo_pago == null) {
          this.mostrarToast('Error!', 'Debes selecionar método de pago.', 'error')
          return
        }
        if (this.row.total == 0 || this.row.total == null) {
          this.mostrarToast('Error!', 'Debes calcular total.', 'error')
          return
        }
        let fecha = new Date()
        this.row.fecha_orden = this.$moment(fecha).utc()
        let row = Object.assign({}, this.row)
        let res
        // if (row.id) {
        //   delete row.id
        //   delete row.categoria
        // res = await this.$store.dispatch('put', { path: 'multipuntos/modify/' + this.row.id, data: row })
        // } else {
        delete row.coordenadas_inicio
        let data = {
          datos: row,
          puntos: this.calculosMultipuntos,
        }
        res = await this.$store.dispatch('post', { path: '/multipuntos/new/', data: data })
        // }
        //this.getData()
        this.snackbar = {
          color: 'success',
          icon: 'success',
          mode: 'multi-line',
          title: 'Muy bien!',
          text: 'Registro procesado correctamente!.',
          timeout: '2500',
        }
        this.isDialogVisible = false
        this.isSnackbarVisible = true
        console.log(res)
      } catch (error) {
        console.log(error)
      }
    },
    mapClicked(event) {
      //alert('click')
      console.log(event)
      //console.log(event.mapboxEvent.lngLat.lng+ ','+event.mapboxEvent.lngLat.lat)
      this.coordinates = []
      this.coordenadas = event.mapboxEvent.lngLat.lng + ', ' + event.mapboxEvent.lngLat.lat
      //this.coordenadas_ubicacion = this.coordenadas
      if (this.tipocoord == 1)
        this.row.coordenadas_inicio = event.mapboxEvent.lngLat.lat + ',' + event.mapboxEvent.lngLat.lng

      if (this.tipocoord == 2)
        this.row.coordenadas_entrega = event.mapboxEvent.lngLat.lat + ',' + event.mapboxEvent.lngLat.lng

      let data = { coordinates: JSON.parse('[' + this.coordenadas + ']'), cliente_id: 1 }
      //vector["coordinates"]=item.ubicacion
      this.coordinates.push(data)
    },
    showMap(type) {
      this.coordinates = []
      this.showMapa = !this.showMapa
      this.tipocoord = type
    },
    async getDataCoords(cc1, cc2) {
      let accessToken = 'pk.eyJ1IjoieWFxdWkiLCJhIjoiY2wzcjF3bXljMGh4czNibXZkY2tseWdnMiJ9.g7nt6H62ljJjIG72Kws1dA'
      let co1 = cc1.split(',')
      let co2 = cc2.split(',')
      console.log(co1)
      console.log(co2)
      let lng1 = co1[1]
      let lat1 = co1[0]
      let lng2 = co2[1]
      let lat2 = co2[0]

      let endPoint = `https://api.mapbox.com/directions/v5/mapbox/driving-traffic/${lng1}%2C${lat1}%3B${lng2}%2C${lat2}?alternatives=true&geometries=geojson&language=en&overview=simplified&steps=true&access_token=pk.eyJ1IjoieWFxdWkiLCJhIjoiY2wzcjF3bXljMGh4czNibXZkY2tseWdnMiJ9.g7nt6H62ljJjIG72Kws1dA`

      //consumir servicio
      //console.log('endPoint ////////////////////////',endPoint)
      const queryCal = await fetch(endPoint, { method: 'GET' })
      return queryCal
    },
    async calcularPrecio() {
      //obtener distancia, tiempo etc.
      this.loading = true
      let dataCoord = await this.getDataCoords(this.row.coordenadas_inicio, this.row.coordenadas_entrega)
      let resp = await dataCoord.json()
      let tiempoViaje = (resp.routes[0].duration.toFixed(0) / 60).toFixed(2)
      this.distanciaViaje = resp.routes[0].distance //< 1000 ? resp.routes[0].distance.toFixed(2)  : (resp.routes[0].distance / 1000).toFixed(2)

      console.log('dataco', tiempoViaje)
      console.log('dataco', this.distanciaViaje)

      let resTarifas = await this.$store.dispatch('get', { path: 'tarifas/getById/1' })
      console.log('resTarifas', resTarifas)
      this.loading = false
      let precio_x_distancia =
        this.distanciaViaje < 5 ? resTarifas.precio_x_metro_30_max : resTarifas.precio_x_metro_60_max
      this.row.total =
        Number(this.distanciaViaje) * precio_x_distancia +
        resTarifas.precio_x_retirar +
        resTarifas.publicidad +
        resTarifas.precio_x_deposito +
        resTarifas.precio_x_entregar
      this.calculos = `Distancia aprox.: ${this.distanciaViaje} m., Tiempo aprox.: ${tiempoViaje} min.`
      //obtener tarifas para calcular precio
    },
    async saveData() {
      try {
        //pendiente la validación del form
        // this.formHasErrors = false
        let row = Object.assign({}, this.row)
        // console.log('row', row)

        // Object.keys(row).forEach(f => {
        //   if (f == 'nombre') {
        //     if (!row[f]) this.formHasErrors = true

        //     this.$refs[f].validate(true)
        //   }
        // })
        ///
        let res
        if (row.id) {
          //es update
          delete row.id
          delete row.categoria
          res = await this.$store.dispatch('put', { path: this.table + '/modify/' + this.row.id, data: row })
        } else {
          res = await this.$store.dispatch('post', { path: this.table + '/new/', data: this.row })
          //es post
        }
        this.getData()
        this.snackbar = {
          color: 'success',
          icon: 'success',
          mode: 'multi-line',
          title: 'Muy bien!',
          text: 'Registro procesado correctamente!.',
          timeout: '2500',
        }

        this.isDialogVisible = false
        this.isSnackbarVisible = true
        console.log(res)
      } catch (error) {
        console.log(error)
      }
    },
    showModal(type, item) {
      if (type == 1) {
        this.modalTitle = 'Nueva orden'
        this.row = {
          coordenadas_inicio: '',
          coordenadas_entrega: '',
        }
        //this.calculos= ''
        this.calculos = ''
        this.isDialogVisible = true
      }
      if (type == 2) {
        this.row = item
        this.modalTitle = 'Editar orden'
        this.calculos = ''
        this.isDialogVisible = true
      }
      if (type == 3) {
        this.row = {
          coordenadas_inicio: '',
          coordenadas_entrega: '',
        }
        this.modalTitle = 'Nueva orden'
        this.calculos = ''
        this.isDialogVisible = false
        this.isDialogVisible2 = true
      }
    },
    async getData() {
      try {
        this.loading = true
        let res = await this.$store.dispatch('get', { path: this.table + '/getAll/' })
        this.loading = false
        if (res.length > 0) {
          this.items = res
          this.totalItems = this.items.length
        }
      } catch (error) {
        this.items = []
        this.loading = false
        console.error('error', error)
      }
    },
    async getCustomers() {
      try {
        this.loading = true
        let res = await this.$store.dispatch('get', { path: 'clientes/getAll/' })
        this.loading = false
        if (res.length > 0) {
          console.log(res)
          this.customersList = res
        }
      } catch (error) {
        this.customersList = []
        this.loading = false
        console.error('error', error)
      }
    },
    async getCommerce() {
      try {
        this.loading = true
        let res = await this.$store.dispatch('get', { path: 'comercios/getAll/' })
        this.loading = false
        if (res.length > 0) {
          console.log(res)
          this.commerceList = res
        }
      } catch (error) {
        this.commerceList = []
        this.loading = false
        console.error('error', error)
      }
    },
    async getCategories() {
      try {
        this.loading = true
        let res = await this.$store.dispatch('get', { path: 'categorias/getAll/' })
        this.loading = false
        if (res.length > 0) {
          console.log(res)
          this.categoriesList = res
        }
      } catch (error) {
        this.categoriesList = []
        this.loading = false
        console.error('error', error)
      }
    },
    async getSubcategoriesByCategory(category_id) {
      try {
        console.log('sel', category_id)
        this.loading = true
        let res = await this.$store.dispatch('get', { path: 'subcategorias/getAllByCategory/' + category_id })
        this.loading = false
        if (res.length > 0) {
          console.log(res)
          this.subcategoriesList = res
        } else {
          this.subcategoriesList = []
          this.row.subcategoria_id = 0
        }
      } catch (error) {
        this.subcategoriesList = []
        this.loading = false
        console.error('error', error)
      }
    },
    async deleteData(item) {
      try {
        let txt
        if (confirm('¿Eliminar Registro?')) {
          this.loading = true
          let res = await this.$store.dispatch('delete', { path: this.table + '/' + item.id })
          await this.getData()
          this.loading = false
          txt = 'You pressed OK!'
          this.snackbar = {
            color: 'success',
            icon: 'success',
            mode: 'multi-line',
            title: 'Muy bien!',
            text: 'Registro elimiado correctamente!.',
            timeout: '2500',
          }
          this.isSnackbarVisible = true
        } else {
          //txt = 'You pressed Cancel!'
        }
      } catch (error) {
        console.log(error)
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/apps/user.scss';

.form-control {
  display: block;
  width: 100%;
  height: 34px;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
}
</style>
